import React, { useState, useEffect, useRef } from 'react'
import './qrCode.css'
import { useDispatch, useSelector } from 'react-redux'
import { getSite } from './../../../actions/site'
import Loader from '../Loader/Loader'
import { Link } from 'react-router-dom'

import { Fab, TextField, TextareaAutosize, Grid } from '@material-ui/core'
import { ArrowBack, GetApp } from '@material-ui/icons'
import QRcode from 'qrcode.react'
import QrReader from 'react-qr-scanner'

const QrCode = ({ history }) => {
  const { user, isAuthenticated } = useSelector((state) => state.user)
  const { loading, sites } = useSelector((state) => state.sites)
  const [qrscan, setQrscan] = useState('No result')

  const [qr, setQr] = useState(
    user && !loading
      ? `Name: ${user.name}, Email: ${user.email}, Role: ${user.role}, Root: ${user.root}`
      : '',
  )

  const handleScan = (data) => {
    if (data) {
      setQrscan(data.text)
    }
  }
  const handleError = (err) => {
    console.error(err)
  }

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSite())
  }, [dispatch])

  useEffect(() => {
    if (isAuthenticated === false) {
      history.push('/login')
    }
  }, [history, isAuthenticated])

  const downloadQR = () => {
    const canvas = document.getElementById('myqr')
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream')
    let downloadLink = document.createElement('a')
    downloadLink.href = pngUrl
    downloadLink.download = 'myqr.png'
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {sites &&
            sites.map((site) => (
              <div>
                {/*  Inner page title  */}
                <div className="inner-page-title-area qr-code">
                  <div className="container">
                    {/* breadcrumb */}
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        About Us
                      </li>
                    </ol>
                    {/* breadcrumb end */}
                    <div className="row">
                      <div className="col-lg-6 offset-lg-3">
                        {/* title & des */}
                        <h1 className="text-white">
                          <span>QR Code</span>
                        </h1>
                        {/* title & des end */}
                      </div>
                    </div>
                  </div>
                </div>
                {/*  Inner page title end  */}
                <div className="container mt-5">
                  <div className="d-flex">
                    <Link to="/">
                      <Fab color="primary">
                        <ArrowBack />
                      </Fab>
                    </Link>
                    <Grid item xs={2}>
                      <Fab
                        onClick={downloadQR}
                        style={{ marginLeft: '10px' }}
                        color="primary"
                      >
                        <GetApp />
                      </Fab>
                    </Grid>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div>
                        <TextField
                          style={{ display: 'none' }}
                          value={qr}
                          label="QR content"
                          size="large"
                          variant="outlined"
                          color="primary"
                        />
                      </div>
                      <div>
                        {qr ? (
                          <QRcode
                            id="myqr"
                            style={{ marginTop: '50px' }}
                            value={qr}
                            size={320}
                            includeMargin={true}
                          />
                        ) : (
                          <p>No QR code preview</p>
                        )}
                      </div>
                      <div>
                        {qr ? (
                          <Grid container>
                            <Grid item xs={10}>
                              <TextareaAutosize
                                style={{ display: 'none' }}
                                rowsMax={4}
                                defaultValue={qr}
                                value={qr}
                              />
                            </Grid>
                          </Grid>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 mt-5">
                      <h4 className="text-white">QR Scanner</h4>
                      <div>
                        <QrReader
                          delay={300}
                          onError={handleError}
                          onScan={handleScan}
                          style={{ width: '100%' }}
                          facingMode="environment"
                        />
                      </div>
                      <TextareaAutosize
                        style={{
                          fontSize: '18px',
                          width: '100%',
                          height: '50px',
                          marginTop: '20px',
                          marginBottom: '20px',
                          paddingBottom: "90px"
                        }}
                        rowsMax={4}
                        defaultValue={qrscan}
                        value={qrscan}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </>
      )}
    </>
  )
}
export default QrCode
