import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { Button } from '@material-ui/core'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import PersonIcon from '@material-ui/icons/Person'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import SideBar from './Sidebar'
import { UPDATE_USER_RESET } from '../../constants/user'
import {
  getAllUsers,
  getUserDetails,
  updateUser,
  clearErrors,
} from '../../actions/user'
import Loader from '../layout/Loader/Loader'

const UpdateUser = ({ history, match }) => {
  const dispatch = useDispatch()
  const alert = useAlert()

  const { loading, error, user } = useSelector((state) => state.userDetails)
  const { user: userLogin } = useSelector((state) => state.user)

  const { loading: updateLoading, error: updateError, isUpdated } = useSelector(
    (state) => state.profile,
  )

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [role, setRole] = useState('')
  const [root, setRoot] = useState('')

  const userId = match.params.id

  useEffect(() => {
    if (userLogin.root === 'No' && user.root === 'Yes') {
      history.push('/admin/users')
      setTimeout(() => {
        window.location.reload()
      }, 1000)
      return
    }
    if (user && user._id !== userId) {
      dispatch(getUserDetails(userId))
    } else {
      setName(user.name)
      setEmail(user.email)
      setRole(user.role)
      setRoot(user.root)
    }
    if (error) {
      alert.error(error)
      dispatch(clearErrors())
    }

    if (updateError) {
      alert.error(updateError)
      dispatch(clearErrors())
    }

    if (isUpdated) {
      alert.success('User Updated Successfully')
      history.push('/admin/users')
      setTimeout(() => {
        window.location.reload()
      }, 1000)
      dispatch({ type: UPDATE_USER_RESET })
    }
  }, [dispatch, alert, error, history, isUpdated, updateError, user, userId])

  const updateUserSubmitHandler = (e) => {
    e.preventDefault()

    const myForm = new FormData()

    myForm.set('name', name)
    myForm.set('email', email)
    myForm.set('role', role)
    myForm.set('root', root)

    dispatch(updateUser(userId, myForm))
  }

  return (
    <Fragment>
      <div className="dashboard">
        <SideBar />
        <div className="newServiceContainer">
          {loading ? (
            <Loader />
          ) : (
            <form
              className="createServiceForm"
              onSubmit={updateUserSubmitHandler}
            >
              <h1>Update User</h1>

              <div>
                <PersonIcon />
                <input
                  type="text"
                  placeholder="Name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <MailOutlineIcon />
                <input
                  type="email"
                  placeholder="Email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div>
                <VerifiedUserIcon />
                <select
                  value={role}
                  disabled={
                    updateLoading
                      ? true
                      : false || role === ''
                      ? true
                      : false || userLogin.root === 'No'
                      ? true
                      : false
                  }
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value="">Choose Role</option>
                  <option value="admin">Admin</option>
                  <option value="user">User</option>
                </select>
              </div>

              <div>
                <VerifiedUserIcon />
                <select
                  disabled={
                    updateLoading
                      ? true
                      : false || role === ''
                      ? true
                      : false || userLogin.root === 'No'
                      ? true
                      : false
                  }
                  onChange={(e) => setRoot(e.target.value)}
                  value={root}
                >
                  <option value="">Choose Root</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>

              <Button
                id="createServiceBtn"
                type="submit"
                disabled={
                  updateLoading ? true : false || role === '' ? true : false
                }
              >
                Update
              </Button>
            </form>
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default UpdateUser
