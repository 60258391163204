import React from 'react'
import { Link } from 'react-router-dom'
import { Rating } from '@material-ui/lab'

const ServiceDetailsCard = ({ service }) => {
  const options = {
    value: service.ratings,
    readOnly: true,
    precision: 0.5,
  }
  return (
    <div class="col mb-30">
      {/* service item */}
      <div class="service-item">
        <div class="ser-img mb-25">
          <img
            src={service.images[0].url}
            alt={service.name}
            style={{ width: '100%', height: '300px' }}
          />
        </div>
        <div className="d-flex">
          <div>
            <Rating {...options} />
          </div>
          <div className="btn-style-3">
            {service.numOfReviews}
            {service.numOfReviews.length > 1 ? 'Reviews' : 'Review'}
          </div>
        </div>
        <div class="description">
          <h6 className="text-dark">{service.name}</h6>
          <p className="text-dark">{(service.description).substring(0, 100)}...</p>
          <br />
          <Link to={`/service/${service._id}`} className="text-dark more_btn">
            Read More <i className="fas fa-caret-right"></i>
          </Link>
        </div>
      </div>
      {/* service item */}
    </div>
  )
}

export default ServiceDetailsCard
