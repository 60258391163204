import axios from 'axios'

import {
  ALL_SITE_FAIL,
  ALL_SITE_REQUEST,
  ALL_SITE_SUCCESS,
  ADMIN_SITE_REQUEST,
  ADMIN_SITE_SUCCESS,
  ADMIN_SITE_FAIL,
  NEW_SITE_REQUEST,
  NEW_SITE_SUCCESS,
  NEW_SITE_FAIL,
  UPDATE_SITE_REQUEST,
  UPDATE_SITE_SUCCESS,
  UPDATE_SITE_FAIL,
  DELETE_SITE_REQUEST,
  DELETE_SITE_SUCCESS,
  DELETE_SITE_FAIL,
  SITE_DETAILS_REQUEST,
  SITE_DETAILS_FAIL,
  SITE_DETAILS_SUCCESS,
  CLEAR_ERRORS,
} from '../constants/site'

// Get All Sites
export const getSite = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_SITE_REQUEST })

    const { data } = await axios.get(`/api/sites`)

    dispatch({
      type: ALL_SITE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ALL_SITE_FAIL,
      payload: error.response.data.message,
    })
  }
}

// Get All Sites For Admin
export const getAdminSite = () => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_SITE_REQUEST })

    const { data } = await axios.get(`/api/admin/sites`)

    dispatch({
      type: ADMIN_SITE_SUCCESS,
      payload: data.sites,
    })
  } catch (error) {
    dispatch({
      type: ADMIN_SITE_FAIL,
      payload: error.response.data.message,
    })
  }
}

// Create Site
export const createSite = (siteData) => async (dispatch) => {
  try {
    dispatch({ type: NEW_SITE_REQUEST })

    const config = {
      headers: { 'Content-Type': 'application/json' },
    }

    const { data } = await axios.post(`/api/admin/site/new`, siteData, config)

    dispatch({
      type: NEW_SITE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: NEW_SITE_FAIL,
      payload: error.response.data.message,
    })
  }
}

// Update Site
export const updateSite = (id, siteData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_SITE_REQUEST })

    const config = {
      headers: { 'Content-Type': 'application/json' },
    }

    const { data } = await axios.put(`/api/admin/site/${id}`, siteData, config)

    dispatch({
      type: UPDATE_SITE_SUCCESS,
      payload: data.success,
    })
  } catch (error) {
    dispatch({
      type: UPDATE_SITE_FAIL,
      payload: error.response.data.message,
    })
  }
}

// Delete Site
export const deleteSite = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_SITE_REQUEST })

    const { data } = await axios.delete(`/api/admin/site/${id}`)

    dispatch({
      type: DELETE_SITE_SUCCESS,
      payload: data.success,
    })
  } catch (error) {
    dispatch({
      type: DELETE_SITE_FAIL,
      payload: error.response.data.message,
    })
  }
}

// Get Sites Details
export const getSiteDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: SITE_DETAILS_REQUEST })

    const { data } = await axios.get(`/api/site/${id}`)

    dispatch({
      type: SITE_DETAILS_SUCCESS,
      payload: data.site,
    })
  } catch (error) {
    dispatch({
      type: SITE_DETAILS_FAIL,
      payload: error.response.data.message,
    })
  }
}

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS })
}
