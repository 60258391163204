import './App.css'
import './Helper.css'
import './Venobox.css'
import { useEffect } from 'react'
import Header from './component/layout/Header/Header.js'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import WebFont from 'webfontloader'
import React from 'react'
import Footer from './component/layout/Footer/Footer'
import Home from './component/Home/Home'
import ServiceDetails from './component/Service/ServiceDetails'
import Services from './component/Service/Services'
import Search from './component/Service/Search'
import LoginSignUp from './component/User/LoginSignUp'
import store from './store'
import { loadUser } from './actions/user'
import UserOptions from './component/layout/Header/UserOptions'
import { useSelector } from 'react-redux'
import Profile from './component/User/Profile'
import ProtectedRoute from './component/Route/ServiceRoute'
import UpdateProfile from './component/User/UpdateProfile'
import UpdatePassword from './component/User/UpdatePassword'
import ForgotPassword from './component/User/ForgotPassword'
import ResetPassword from './component/User/ResetPassword'
import Dashboard from './component/Admin/Dashboard.js'
import ServiceList from './component/Admin/ServiceList.js'
import NewService from './component/Admin/NewService'
import UpdateService from './component/Admin/UpdateService'
import UsersList from './component/Admin/UsersList'
import UpdateUser from './component/Admin/UpdateUser'
import ServiceReviews from './component/Admin/ServiceReviews'
import Contact from './component/layout/Contact/Contact'
import About from './component/layout/About/About'
import NotFound from './component/layout/Not Found/NotFound'
import Faq from './component/layout/Faq/Faq'
import PrivacyPolicy from './component/layout/PrivacyPolicy/PrivacyPolicy'
import TermsCondition from './component/layout/TermsCondition/TermsCondition'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import NewSite from './component/Admin/NewSite';
import SiteList from './component/Admin/SiteList';
import UpdateSite from './component/Admin/UpdateSite';
import QrCode from './component/layout/QrCode/QrCode';

function App() {
  const { isAuthenticated, user } = useSelector((state) => state.user)

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Roboto', 'Droid Sans', 'Chilanka'],
      },
    })

    store.dispatch(loadUser())
  }, [])

  window.addEventListener('contextmenu', (e) => e.preventDefault())

  return (
    <Router>
      <Header />

      {/* {isAuthenticated && <UserOptions user={user} />} */}

      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/service/:id" component={ServiceDetails} />
        <Route exact path="/services" component={Services} />
        <Route path="/services/:keyword" component={Services} />

        <Route exact path="/search" component={Search} />

        <Route exact path="/contact" component={Contact} />

        <Route exact path="/about" component={About} />

        <Route exact path="/faq" component={Faq} />

        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        
        <Route exact path="/terms-condition" component={TermsCondition} />

        <ProtectedRoute exact path="/account" component={Profile} />

        <ProtectedRoute exact path="/me/update" component={UpdateProfile} />

        <ProtectedRoute
          exact
          path="/password/update"
          component={UpdatePassword}
        />

        <Route exact path="/password/forgot" component={ForgotPassword} />

        <Route exact path="/password/reset/:token" component={ResetPassword} />

        <Route exact path="/login" component={LoginSignUp} />

        <Route exact path="/qrcode" component={QrCode} />

        <ProtectedRoute
          isAdmin={true}
          exact
          path="/admin/dashboard"
          component={Dashboard}
        />

        <ProtectedRoute
          exact
          path="/admin/services"
          isAdmin={true}
          component={ServiceList}
        />

        <ProtectedRoute
          exact
          path="/admin/sites"
          isAdmin={true}
          component={SiteList}
        />

        <ProtectedRoute
          exact
          path="/admin/service"
          isAdmin={true}
          component={NewService}
        />

        <ProtectedRoute
          exact
          path="/admin/site"
          isAdmin={true}
          component={NewSite}
        />

        <ProtectedRoute
          exact
          path="/admin/service/:id"
          isAdmin={true}
          component={UpdateService}
        />

        <ProtectedRoute
          exact
          path="/admin/site/:id"
          isAdmin={true}
          component={UpdateSite}
        />

        <ProtectedRoute
          exact
          path="/admin/users"
          isAdmin={true}
          component={UsersList}
        />

        <ProtectedRoute
          exact
          path="/admin/user/:id"
          isAdmin={true}
          component={UpdateUser}
        />

        <ProtectedRoute
          exact
          path="/admin/reviews"
          isAdmin={true}
          component={ServiceReviews}
        />

        <Route
          component={
            window.location.pathname === '/process/payment' ? null : NotFound
          }
        />
      </Switch>

      <Footer />
    </Router>
  )
}

export default App
