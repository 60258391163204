import React, { useEffect } from 'react'
import './About.css'
import { useDispatch, useSelector } from 'react-redux'
// import Team from '../Team/Team'
import { Link } from 'react-router-dom'
import Counter from '../Counter/Counter'
import Features from '../Features/Features'
import { getSite } from './../../../actions/site'
import Loader from '../Loader/Loader'

const About = () => {
  const { loading, sites } = useSelector((state) => state.sites)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSite())
  }, [dispatch])

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {sites &&
            sites.map((site) => (
              <div>
                {/*  Inner page title  */}
                <div className="inner-page-title-area about-us">
                  <div className="container">
                    {/* breadcrumb */}
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        About Us
                      </li>
                    </ol>
                    {/* breadcrumb end */}
                    <div className="row">
                      <div className="col-lg-6 offset-lg-3">
                        {/* title & des */}
                        <h1>
                          <span>About Us</span>
                        </h1>
                        {/* title & des end */}
                      </div>
                    </div>
                  </div>
                </div>
                {/*  Inner page title end  */}

                {/*  Features area  */}
                {/* <Features /> */}
                {/*  Features area end  */}

                {/*  About area  */}
                <div className="about-area pt-80 pb-50 theme-bg-white">
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <div className="about-img-wrapper">
                          <div className="row align-items-center">
                            <div className="col-lg-5 mb-30">
                              <div className="about-images-1">
                                <img src="img/about/about-1.jpg" alt="" />
                              </div>
                            </div>
                            <div className="col-lg-7 mb-30">
                              <div className="about-images-2">
                                <img src="img/about/about-2.jpg" alt="" />
                              </div>
                              <div className="about-images-3 mt-30">
                                <img src="img/about/about-3.jpg" alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5 offset-lg-1 mb-30">
                        <div className="about-content">
                          <div className="about-content-text">
                            <h6 className="text-dark">About Us</h6>
                            <h2 className="text-dark">
                              A big opportunity for your business growth
                            </h2>
                            <p className="text-dark">{site.about}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*  About area end  */}

                {/*  Counter area  */}
                <Counter />
                {/*  Counter area end  */}

                {/*  Team area  */}
                {/* <Team /> */}
                {/*  Team area end  */}
              </div>
            ))}
        </div>
      )}
    </>
  )
}

export default About
