import React from 'react'
import './Counter.css'

const Counter = () => {
  return (
    <div className="counter-area pt-100 pb-70">
      <div className="container">
        <div className="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2 row-cols-1">
          <div className="col mb-30">
            {/* single counter */}
            <div className="single-counter-box">
              <div className="text-wrap">
                <div className="content">
                  <div className="counter-info-text">
                    <span className="counter">300+</span>
                  </div>
                  <h4 className="title text-white">Successful Deliveries</h4>
                </div>
              </div>
            </div>
            {/* single counter end */}
          </div>
          <div className="col mb-30">
            {/* single counter */}
            <div className="single-counter-box">
              <div className="text-wrap">
                <div className="content">
                  <div className="counter-info-text">
                    <span className="counter">200+</span>
                  </div>
                  <h4 className="title text-white">Happy Clients</h4>
                </div>
              </div>
            </div>
            {/* single counter end */}
          </div>
          <div className="col mb-30">
            {/* single counter */}
            <div className="single-counter-box">
              <div className="text-wrap">
                <div className="content">
                  <div className="counter-info-text">
                    <span className="counter">500+</span>
                  </div>
                  <h4 className="title text-white">Hours of Work</h4>
                </div>
              </div>
            </div>
            {/* single counter end */}
          </div>
          <div className="col mb-30">
            {/* single counter */}
            <div className="single-counter-box">
              <div className="text-wrap">
                <div className="content">
                  <div className="counter-info-text">
                    <span className="counter">400+</span>
                  </div>
                  <h4 className="title text-white">Solutions</h4>
                </div>
              </div>
            </div>
            {/* single counter end */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Counter
