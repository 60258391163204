import {
  ALL_SITE_FAIL,
  ALL_SITE_REQUEST,
  ALL_SITE_SUCCESS,
  ADMIN_SITE_REQUEST,
  ADMIN_SITE_SUCCESS,
  ADMIN_SITE_FAIL,
  NEW_SITE_REQUEST,
  NEW_SITE_SUCCESS,
  NEW_SITE_FAIL,
  NEW_SITE_RESET,
  UPDATE_SITE_REQUEST,
  UPDATE_SITE_SUCCESS,
  UPDATE_SITE_FAIL,
  UPDATE_SITE_RESET,
  DELETE_SITE_REQUEST,
  DELETE_SITE_SUCCESS,
  DELETE_SITE_FAIL,
  DELETE_SITE_RESET,
  SITE_DETAILS_REQUEST,
  SITE_DETAILS_FAIL,
  SITE_DETAILS_SUCCESS,
  CLEAR_ERRORS,
} from "../constants/site";

export const sitesReducer = (state = { sites: [] }, action) => {
  switch (action.type) {
    case ALL_SITE_REQUEST:
    case ADMIN_SITE_REQUEST:
      return {
        loading: true,
        sites: [],
      };
    case ALL_SITE_SUCCESS:
      return {
        loading: false,
        sites: action.payload.sites,
        sitesCount: action.payload.sitesCount,
        resultPerPage: action.payload.resultPerPage,
        filteredSitesCount: action.payload.filteredSitesCount,
      };

    case ADMIN_SITE_SUCCESS:
      return {
        loading: false,
        sites: action.payload,
      };
    case ALL_SITE_FAIL:
    case ADMIN_SITE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const newSiteReducer = (state = { site: {} }, action) => {
  switch (action.type) {
    case NEW_SITE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_SITE_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        site: action.payload.site,
      };
    case NEW_SITE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_SITE_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const siteReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_SITE_REQUEST:
    case UPDATE_SITE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_SITE_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_SITE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_SITE_FAIL:
    case UPDATE_SITE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_SITE_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_SITE_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const siteDetailsReducer = (state = { site: {} }, action) => {
  switch (action.type) {
    case SITE_DETAILS_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case SITE_DETAILS_SUCCESS:
      return {
        loading: false,
        site: action.payload,
      };
    case SITE_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

