import React, { useState, useEffect } from 'react'
import './Contact.css'
import { Link } from 'react-router-dom'
import { getSite } from './../../../actions/site'
import { useDispatch, useSelector } from 'react-redux'
import Loader from './../Loader/Loader'
import emailjs from 'emailjs-com'
import { useAlert } from 'react-alert'
import Input from 'react-phone-number-input/input'

const Contact = () => {
  const [phoneNo, setPhoneNo] = useState('')
  const [sending, setSending] = useState(false)

  const { loading, sites } = useSelector((state) => state.sites)
  const alert = useAlert()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSite())
  }, [dispatch])

  const sendMail = (e) => {
    e.preventDefault()
    setSending(true)
    emailjs
      .sendForm(
        'spezin',
        'template_39tdeiq',
        e.target,
        'user_UdgQW2uxRt0vdGCQzkK9Y',
      )
      .then((res) => {
        alert.success(
          'Message sent....we will get back to you as soon as possible.',
        )
        setSending(false)
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      })
      .catch((err) => {
        alert.error('Message Failed. Please try again.')
        setSending(false)
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      })
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {sites &&
            sites.map((site) => (
              <div>
                {/*  Inner page title  */}
                <div className="inner-page-title-area contact-us">
                  <div className="container">
                    {/* breadcrumb */}
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Contact Us
                      </li>
                    </ol>
                    {/* breadcrumb end */}
                    <div className="row">
                      <div className="col-lg-6 offset-lg-3">
                        {/* title & des */}
                        <h1>
                          <span>Contact Us</span>
                        </h1>
                        {/* title & des end */}
                      </div>
                    </div>
                  </div>
                </div>
                {/*  Inner page title end  */}

                {/*  Contact us page  */}
                <div className="contact-us-page pt-80 pb-50">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-4 col-md-6 mb-30">
                        {/* contact item box */}
                        <div className="contact-item-box">
                          <div className="contact-item-grid">
                            <h4>Address</h4>
                            <p className="mb-0">{site.address}</p>
                          </div>
                          <div className="contact-item-grid">
                            <h4>Phone 1</h4>
                            <p className="mb-0">
                              <a href={`tel:+${site.phoneNo}`}>
                                <i className="fas fa-phone-alt"></i>+
                                {site.phoneNo}
                              </a>
                            </p>
                          </div>
                          <div className="contact-item-grid">
                            <h4>Country</h4>
                            <p className="mb-0">{site.country}</p>
                          </div>
                          <div className="contact-item-grid">
                            <div className="contact-social">
                              <a
                                href={site.facebookUrl ? site.facebookUrl : '#'}
                              >
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a href={site.twitterUrl ? site.twitterUrl : '#'}>
                                <i className="fab fa-twitter"></i>
                              </a>
                              <a
                                href={
                                  site.instagramUrl ? site.instagramUrl : '#'
                                }
                              >
                                <i className="fab fa-instagram"></i>
                              </a>
                              <a
                                href={site.whatsappUrl ? site.whatsappUrl : '#'}
                              >
                                <i className="fab fa-whatsapp"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                        {/* contact item box end */}
                      </div>
                      <div className="col-lg-4 col-md-6 mb-30">
                        {/* contact item box */}
                        <div className="contact-item-box">
                          <div className="contact-item-grid">
                            <h4>Email</h4>
                            <p className="mb-0">
                              <a href={`mailto:${site.email}`}>
                                <i className="far fa-envelope"></i>
                                {site.email}
                              </a>
                            </p>
                          </div>
                          <div className="contact-item-grid">
                            <h4>Phone 2</h4>
                            <p className="mb-0">
                              <a href={`tel:+${site.phoneNo2}`}>
                                <i className="fas fa-phone-alt"></i>+
                                {site.phoneNo2}
                              </a>
                            </p>
                          </div>
                          <div className="contact-item-grid">
                            <h4>State/Region</h4>
                            <p className="mb-0">{site.state}</p>
                          </div>

                          <div className="contact-item-grid">
                            <div className="contact-social">
                              <div className="contact-social">
                                <a
                                  href={
                                    site.facebookUrl ? site.facebookUrl : '#'
                                  }
                                >
                                  <i className="fab fa-facebook-f"></i>
                                </a>
                                <a
                                  href={site.twitterUrl ? site.twitterUrl : '#'}
                                >
                                  <i className="fab fa-twitter"></i>
                                </a>
                                <a
                                  href={
                                    site.instagramUrl ? site.instagramUrl : '#'
                                  }
                                >
                                  <i className="fab fa-instagram"></i>
                                </a>
                                <a
                                  href={
                                    site.whatsappUrl ? site.whatsappUrl : '#'
                                  }
                                >
                                  <i className="fab fa-whatsapp"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* contact item box end */}
                      </div>
                      <div className="col-lg-4 col-md-12 mb-30">
                        {/* contact form box */}
                        <div className="contact-form-box">
                          <h3 className="text-dark mb-15">Contact Form</h3>
                          <form
                            onSubmit={sendMail}
                            className="contact-form"
                            id="contact-form"
                          >
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control text-dark"
                                name="name"
                                id="floatingInput1"
                                placeholder="Name"
                                required
                                data-error="Name is required"
                              />
                              <label for="floatingInput1">Name</label>
                              <div className="help-block with-errors"></div>
                            </div>
                            <div className="form-floating mb-3">
                              <input
                                type="email"
                                className="form-control text-dark"
                                name="email"
                                id="floatingInput2"
                                placeholder="E-mail"
                                required
                                data-error="E-mail is required"
                              />
                              <label for="floatingInput2">E-mail</label>
                              <div className="help-block with-errors"></div>
                            </div>
                            <div className="row">
                              <div className="form-floating mb-3 col-lg-6 col-md-12">
                                <Input
                                  value={phoneNo}
                                  onChange={setPhoneNo}
                                  type="text"
                                  className="form-control pl-3 pr-3 text-dark"
                                  name="phone"
                                  id="floatingInput3"
                                  placeholder="Phone"
                                  required
                                  data-error="Phone is required"
                                />
                                <label
                                  for="floatingInput3"
                                  style={{ marginLeft: '10px' }}
                                >
                                  Phone
                                </label>
                                <div className="help-block with-errors"></div>
                              </div>
                              <div className="form-floating mb-3 col-lg-6 col-md-12">
                                <input
                                  type="text"
                                  className="form-control text-dark"
                                  name="subject"
                                  id="floatingInput3"
                                  placeholder="Subject"
                                  required
                                  data-error="Subject is required"
                                />
                                <label
                                  for="floatingInput3"
                                  style={{ marginLeft: '10px' }}
                                >
                                  Subject
                                </label>
                                <div className="help-block with-errors"></div>
                              </div>
                            </div>
                            <div className="form-floating mb-3">
                              <textarea
                                className="form-control text-dark"
                                name="message"
                                id="floatingTextarea"
                                placeholder="Your Message"
                                required
                                data-error="Message is required"
                              ></textarea>
                              <label for="floatingTextarea">Your Message</label>
                              <div className="help-block with-errors"></div>
                            </div>
                            {sending ? (
                              <button
                                type="submit"
                                disabled
                                className="btn-style-1"
                              >
                                Sending...
                              </button>
                            ) : (
                              <button type="submit" className="btn-style-1">
                                Send
                              </button>
                            )}
                            <div className="messages"></div>
                          </form>
                        </div>
                        {/* contact form box end */}
                      </div>
                    </div>
                    {/* map box */}
                    <div className="map-box mb-30">
                      <iframe src={`${site.mapUrl}`}></iframe>
                    </div>
                    {/* map box end */}
                  </div>
                </div>
                {/*  Contact us page end  */}
              </div>
            ))}
        </div>
      )}
    </>
  )
}

export default Contact
