import React, { Fragment, useEffect, useState } from 'react'
import Carousel from 'react-material-ui-carousel'
import './ServiceDetails.css'
import { useSelector, useDispatch } from 'react-redux'
import {
  clearErrors,
  getServiceDetails,
  newReview,
} from '../../actions/service'
import ReviewCard from './ReviewCard.js'
import Loader from '../layout/Loader/Loader'
import { useAlert } from 'react-alert'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@material-ui/core'
import { Rating } from '@material-ui/lab'
import { NEW_REVIEW_RESET } from '../../constants/service'
import { Link } from 'react-router-dom'
import { getSite } from './../../actions/site'

const ServiceDetails = ({ match }) => {
  const dispatch = useDispatch()
  const alert = useAlert()

  const { service, loading, error } = useSelector(
    (state) => state.serviceDetails,
  )

  const { user, isAuthenticated } = useSelector((state) => state.user)


  const { sites } = useSelector((state) => state.sites)

  const { success, error: reviewError } = useSelector(
    (state) => state.newReview,
  )


  const options = {
    size: 'large',
    value: service.ratings,
    readOnly: true,
    precision: 0.5,
  }

  const [open, setOpen] = useState(false)
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')

  const submitReviewToggle = () => {
    open ? setOpen(false) : setOpen(true)
  }

  const reviewSubmitHandler = () => {
    if(!isAuthenticated){
      alert.error("Please login to submit review.")
      return
    }
    if(!comment){
      alert.error("Comment is required")
      return
    }
    if(!rating){
      alert.error("Rating is required")
      return
    }
    const myForm = new FormData()

    myForm.set('rating', rating)
    myForm.set('comment', comment)
    myForm.set('serviceId', match.params.id)

    dispatch(newReview(myForm))

    setOpen(false)
  }

  useEffect(() => {
    if (error) {
      alert.error(error)
      dispatch(clearErrors())
    }

    if (reviewError) {
      alert.error(reviewError)
      dispatch(clearErrors())
    }

    if (success) {
      alert.success('Review Submitted Successfully')
      dispatch({ type: NEW_REVIEW_RESET })
    }
    dispatch(getServiceDetails(match.params.id))
  }, [dispatch, match.params.id, error, alert, reviewError, success])

  useEffect(() => {
    dispatch(getSite())
  }, [dispatch])

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <>
            {sites &&
              sites.map((site) => (
                <>
                  {/*  Inner page title  */}
                  <div class="inner-page-title-area services-breadcrumb">
                    <div class="container">
                      {/* breadcrumb */}
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                          Service Details
                        </li>
                      </ol>
                      {/* breadcrumb end */}
                      <div class="row">
                        <div class="col-lg-6 offset-lg-3 text-white">
                          {/* title & des */}
                          <h1>
                            <span>Service Details</span>
                          </h1>
                          {/* title & des end */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*  Inner page title end  */}

                  {/*  Service details page  */}
                  <div class="service-details-page pt-80 pb-50">
                    <div class="container">
                      <div class="row">
                        <div class="col-lg-3">
                          {/* sidebar */}
                          <aside>
                            {/* sidebar item box */}
                            <div class="sidebar-item-box mb-30">
                              {/* sidebar list */}
                              <ul class="sidebar-list">
                                <li>
                                  <Link to={`/service/${service._id}`}>
                                    {service.name}
                                    <i class="fas fa-caret-right"></i>
                                  </Link>
                                </li>
                              </ul>
                              {/* sidebar list end */}
                            </div>
                            {/* sidebar item box end */}
                            {/* sidebar item box */}
                            <div class="sidebar-item-box mb-30">
                              {/* sidebar header */}
                              <div class="sidebar-header">
                                <h5>Contact Us</h5>
                              </div>
                              {/* sidebar header end */}
                              {/* sidebar contact */}
                              <ul class="sidebar-contact">
                                <li>
                                  <a href={`tel:+${site.phoneNo}`}>
                                    Phone <span>+{site.phoneNo}</span>
                                  </a>
                                </li>
                                <li>
                                  <a href={`tel:+${site.phoneNo2}`}>
                                    Phone <span>+{site.phoneNo2}</span>
                                  </a>
                                </li>
                                <li>
                                  <a href={`mailto:${site.email}`}>
                                    Email <span>{site.email}</span>
                                  </a>
                                </li>
                                <li>
                                  <a className='text-white'>
                                    Opening Hours
                                    <span>{site.openingHours}</span>
                                  </a>
                                </li>
                              </ul>
                              {/* sidebar contact end */}
                            </div>
                            {/* sidebar item box end */}
                          </aside>{' '}
                          {/* sidebar end */}
                        </div>
                        <div class="col-lg-9">
                          {/* service content */}
                          <div class="service-content">
                            <h3 class="text-dark mb-20">{service.name}</h3>
                            <div className="detailsBlock-2">
                              <Rating {...options} />
                              <span className="detailsBlock-2-span text-dark">
                                ({service.numOfReviews}
                                {service.numOfReviews > 1
                                  ? 'Reviews'
                                  : 'Review'}
                                )
                              </span>
                            </div>
                            <div class="row">
                              <div class="col-lg-4 col-md-6 col-sm-12 mb-20">
                                <div class="service-detail-img">
                                  <Carousel>
                                    {service.images &&
                                      service.images.map((item, i) => (
                                        <img
                                          className="CarouselImage"
                                          style={{
                                            width: '100%',
                                            height: '300px',
                                          }}
                                          key={i}
                                          src={item.url}
                                          alt={`${i} Slide`}
                                        />
                                      ))}
                                  </Carousel>
                                </div>
                              </div>
                              <div className="col-lg-8 col-md-6 col-sm-12">
                                <p className="text-dark">
                                  {service.description}
                                </p>
                                <button
                                  onClick={submitReviewToggle}
                                  className="submitReview"
                                >
                                  Submit Review
                                </button>
                              </div>
                            </div>
                          </div>
                          {/* service content end */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3 className="reviewsHeading text-dark">REVIEWS</h3>

                  <Dialog
                    aria-labelledby="simple-dialog-title"
                    open={open}
                    onClose={submitReviewToggle}
                  >
                    <DialogTitle>Submit Review</DialogTitle>
                    <DialogContent className="submitDialog">
                      <Rating
                        onChange={(e) => setRating(e.target.value)}
                        value={rating}
                        size="large"
                      />

                      <textarea
                        className="submitDialogTextArea"
                        cols="30"
                        rows="5"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      ></textarea>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={submitReviewToggle} color="secondary">
                        Cancel
                      </Button>
                      <Button onClick={reviewSubmitHandler} color="primary">
                        Submit
                      </Button>
                    </DialogActions>
                  </Dialog>

                  {service.reviews && service.reviews[0] ? (
                    <div className="reviews">
                      {service.reviews &&
                        service.reviews.map((review) => (
                          <ReviewCard key={review._id} review={review} />
                        ))}
                    </div>
                  ) : (
                    <p className="noReviews text-dark">No Reviews Yet</p>
                  )}
                  {/*  Service details page end  */}
                </>
              ))}
          </>
        </Fragment>
      )}
    </Fragment>
  )
}

export default ServiceDetails
