import React, { useEffect } from 'react'
import Sidebar from './Sidebar.js'
import './dashboard.css'
import { Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getAdminService } from '../../actions/service'
import { getAllUsers } from '../../actions/user.js'
import { getSite } from './../../actions/site';

const Dashboard = () => {
  const dispatch = useDispatch()

  const { services } = useSelector((state) => state.services)
  const { sites } = useSelector((state) => state.sites)

  const { users } = useSelector((state) => state.allUsers)

  useEffect(() => {
    dispatch(getAdminService())
    dispatch(getAllUsers())
  }, [dispatch])

  useEffect(() => {
    dispatch(getSite())
  }, [])

  return (
    <div className="dashboard">
      <Sidebar />

      <div className="dashboardContainer">
        <Typography component="h1">Dashboard</Typography>

        <div className="dashboardSummary">
          <div className="dashboardSummaryBox2">
            <Link to="/admin/services" className='dashboard_text'>
              <h5 className="text-white">Service</h5>
              <h5 className="text-white">{services && services.length}</h5>
            </Link>
            <Link to="/admin/users" className='dashboard_text'>
              <h5 className="text-dark">Users</h5>
              <h5 className="text-dark">{users && users.length}</h5>
            </Link>
            <Link to="/account" className='dashboard_text'>
              <h5 className="text-white">Acccount</h5>
              <h5 className="text-white">{sites && sites.length}</h5>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
