import React, { useEffect } from 'react'
import './Home.css'
import ServiceCard from './ServiceCard.js'
import MetaData from '../layout/MetaData'
import { clearErrors, getService } from '../../actions/service'
import { useSelector, useDispatch } from 'react-redux'
import Loader from '../layout/Loader/Loader'
import { useAlert } from 'react-alert'
import Features from '../layout/Features/Features'
import Counter from '../layout/Counter/Counter'
// import Testimonial from '../layout/Testimonial/Testimonial'
import { Link } from 'react-router-dom'
import { getSite } from './../../actions/site'

const Home = () => {
  const alert = useAlert()
  const dispatch = useDispatch()
  const { loading, error, services } = useSelector((state) => state.services)
  const { sites } = useSelector((state) => state.sites)

  useEffect(() => {
    if (error) {
      alert.error(error)
      dispatch(clearErrors())
    }
    dispatch(getService())
  }, [dispatch, error, alert])

  useEffect(() => {
    dispatch(getSite())
  }, [dispatch])

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          {sites &&
            sites.map((site) => (
              <div>
                <MetaData title={`${site.name}`} />
                {/*  Slider area  */}
                <div className="slider" id="home">
                  <div
                    id="carouselExampleControls"
                    class="carousel slide"
                    data-ride="carousel"
                  >
                    <div class="carousel-inner">
                      <div class="carousel-item slider-one active">
                      <div className="carousel-caption">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="slider-caption-box">
                                  <h3>Hello Dears! We Will Help</h3>
                                  <h2 className="mb-15">Road Transportation</h2>
                                  <p>{site.vision}</p>
                                  <Link
                                    className="btn-style-2 mr-6"
                                    to="/services"
                                  >
                                    Services
                                  </Link>
                                  <Link className="btn-style-2" to="/contact">
                                    Contact Us
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item slider-two">
                      <div className="carousel-caption">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="slider-caption-box">
                                  <h3>Hello Dears! We Will Help</h3>
                                  <h2 className="mb-15">
                                    Safe & Fast Logistics
                                  </h2>
                                  <p>{site.vision}</p>
                                  <Link
                                    className="btn-style-2 mr-6"
                                    to="/services"
                                  >
                                    Services
                                  </Link>
                                  <Link className="btn-style-2" to="/contact">
                                    Contact Us
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item slider-three">
                      <div className="carousel-caption">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="slider-caption-box">
                                  <h3>Hello Dears! We Will Help</h3>
                                  <h2 className="mb-15">
                                    Fast Delivery Services
                                  </h2>
                                  <p>{site.vision}</p>
                                  <Link
                                    className="btn-style-2 mr-6"
                                    to="/services"
                                  >
                                    Services
                                  </Link>
                                  <Link className="btn-style-2" to="/contact">
                                    Contact Us
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a
                      class="carousel-control-prev"
                      href="#carouselExampleControls"
                      role="button"
                      data-slide="prev"
                    >
                      <span
                        class="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="sr-only">Previous</span>
                    </a>
                    <a
                      class="carousel-control-next"
                      href="#carouselExampleControls"
                      role="button"
                      data-slide="next"
                    >
                      <span
                        class="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="sr-only">Next</span>
                    </a>
                  </div>
                </div>
                {/*  Slider area end  */}

                {/*  Features area  */}
                {/* <Features /> */}
                {/*  Features area end  */}

                {/*  Services area  */}
                <div className="services-area pt-80 pb-50">
                  <div className="container">
                    {/* section title */}
                    <div className="section-title text-center mb-40">
                      <h2 className="text-dark">Our Services</h2>
                      <span className="border-title"></span>
                    </div>
                    {/* section title end */}
                    <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-1 row-cols-1">
                      {services.map((service) => (
                        <ServiceCard key={service._id} service={service} />
                      ))}
                    </div>
                  </div>
                </div>
                {/*  Services area end  */}

                {/*  About area  */}
                <div className="about-area pt-80 pb-50 theme-bg-white">
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <div className="about-img-wrapper">
                          <div className="row align-items-center">
                            <div className="col-lg-5 mb-30">
                              <div className="about-images-1">
                                <img src="img/about/about-1.jpg" alt="" />
                              </div>
                            </div>
                            <div className="col-lg-7 mb-30">
                              <div className="about-images-2">
                                <img src="img/about/about-2.jpg" alt="" />
                              </div>
                              <div className="about-images-3 mt-30">
                                <img src="img/about/about-3.jpg" alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5 offset-lg-1 mb-30">
                        <div className="about-content">
                          <div className="about-content-text">
                            <h6>About Us</h6>
                            <h2 className="text-dark">
                              A big opportunity for your business growth
                            </h2>
                            <p className="text-dark">
                              {site.about.substring(0, 295)}...
                            </p>
                            <Link to="about" className="btn-style-1 mt-20">
                              Read More
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*  About area end  */}

                {/*  Counter area  */}
                <Counter />
                {/*  Counter area end  */}

                {/*  Call to action area  */}
                <div className="call-to-action-area pt-100 pb-80">
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-lg-7 mb-20">
                        <div className="cta-content">
                          <h3>Our Mission</h3>
                          <h2>We Are Here To Help You</h2>
                          <p className="mb-25">{site.mission}</p>

                          <Link to="/contact" className="btn-style-1">
                            Contact Now
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*  Call to action area end  */}

                {/*  Contact area  */}
                <div className="contact-area pb-50 mt-5">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-7 col-md-5 mb-30">
                        {/* map box */}
                        <div className="map-box">
                          <iframe src={`${site.mapUrl}`}></iframe>
                        </div>
                        {/* map box end */}
                      </div>
                      <div className="col-lg-5 col-md-7 mb-30">
                        {/* contact info */}
                        <div className="contact-info-box">
                          <h3 className="text-dark mb-20 text-decoration-underline">
                            Contact information
                          </h3>
                          <ul className="contact-info">
                            <li>
                              <a
                                href={`tel:+${site.phoneNo}`}
                                className="text-dark"
                              >
                                <i className="fas fa-phone-alt"></i>+
                                {site.phoneNo}
                              </a>
                            </li>
                            <li>
                              <a
                                href={`tel:+${site.phoneNo2}`}
                                className="text-dark"
                              >
                                <i className="fas fa-phone-alt"></i>+
                                {site.phoneNo2}
                              </a>
                            </li>
                            <li>
                              <a
                                href={`mailto:${site.email}`}
                                className="text-dark"
                              >
                                <i className="far fa-envelope"></i>
                                {site.email}
                              </a>
                            </li>
                            <li className="text-dark">
                              <i className="fas fa-map-marker-alt"></i>
                              {site.country}
                            </li>
                            <li className="text-dark">
                              <i className="fas fa-map-marker-alt"></i>
                              {site.state}
                            </li>
                            <li className="text-dark">
                              <i className="fas fa-map-marker-alt"></i>
                              {site.address}
                            </li>
                          </ul>
                          {/* contact social */}
                          <div className="contact-social mt-30">
                            <a href={site.facebookUrl ? site.facebookUrl : '#'}>
                              <i className="fab fa-facebook-f"></i>
                            </a>
                            <a href={site.twitterUrl ? site.twitterUrl : '#'}>
                              <i className="fab fa-twitter"></i>
                            </a>
                            <a
                              href={site.instagramUrl ? site.instagramUrl : '#'}
                            >
                              <i className="fab fa-instagram"></i>
                            </a>
                            <a href={site.whatsappUrl ? site.whatsappUrl : '#'}>
                              <i className="fab fa-whatsapp"></i>
                            </a>
                          </div>
                          {/* contact social end */}
                        </div>
                        {/* contact info end */}
                      </div>
                    </div>
                  </div>
                </div>
                {/*  Contact area end  */}
              </div>
            ))}
        </>
      )}
    </div>
  )
}

export default Home
