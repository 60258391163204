export const ALL_SITE_REQUEST = "ALL_SITE_REQUEST";
export const ALL_SITE_SUCCESS = "ALL_SITE_SUCCESS";
export const ALL_SITE_FAIL = "ALL_SITE_FAIL";

export const ADMIN_SITE_REQUEST = "ADMIN_SITE_REQUEST";
export const ADMIN_SITE_SUCCESS = "ADMIN_SITE_SUCCESS";
export const ADMIN_SITE_FAIL = "ADMIN_SITE_FAIL";

export const NEW_SITE_REQUEST = "NEW_SITE_REQUEST";
export const NEW_SITE_SUCCESS = "NEW_SITE_SUCCESS";
export const NEW_SITE_RESET = "NEW_SITE_RESET";
export const NEW_SITE_FAIL = "NEW_SITE_FAIL";

export const UPDATE_SITE_REQUEST = "UPDATE_SITE_REQUEST";
export const UPDATE_SITE_SUCCESS = "UPDATE_SITE_SUCCESS";
export const UPDATE_SITE_RESET = "UPDATE_SITE_RESET";
export const UPDATE_SITE_FAIL = "UPDATE_SITE_FAIL";

export const DELETE_SITE_REQUEST = "DELETE_SITE_REQUEST";
export const DELETE_SITE_SUCCESS = "DELETE_SITE_SUCCESS";
export const DELETE_SITE_RESET = "DELETE_SITE_RESET";
export const DELETE_SITE_FAIL = "DELETE_SITE_FAIL";

export const SITE_DETAILS_REQUEST = "SITE_DETAILS_REQUEST";
export const SITE_DETAILS_SUCCESS = "SITE_DETAILS_SUCCESS";
export const SITE_DETAILS_FAIL = "SITE_DETAILS_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
