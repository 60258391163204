import React, { Fragment, useEffect } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import './serviceList.css'
import { useSelector, useDispatch } from 'react-redux'
import {
  clearErrors,
  getAdminService,
  deleteService,
} from '../../actions/service'
import { Link } from 'react-router-dom'
import { useAlert } from 'react-alert'
import { Button } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import SideBar from './Sidebar'
import { DELETE_SERVICE_RESET } from '../../constants/service'

const ServiceList = ({ history }) => {
  const dispatch = useDispatch()

  const alert = useAlert()

  const { error, services } = useSelector((state) => state.services)

  const { error: deleteError, isDeleted } = useSelector(
    (state) => state.service,
  )

  const deleteServiceHandler = (id) => {
    var result = window.confirm('Comfirm delete?')
    if (result) {
      dispatch(deleteService(id))
    }
  }

  useEffect(() => {
    if (error) {
      alert.error(error)
      dispatch(clearErrors())
    }

    if (deleteError) {
      alert.error(deleteError)
      dispatch(clearErrors())
    }

    if (isDeleted) {
      alert.success('Service Deleted Successfully')
      history.push('/admin/dashboard')
      dispatch({ type: DELETE_SERVICE_RESET })
    }

    dispatch(getAdminService())
  }, [dispatch, alert, error, deleteError, history, isDeleted])

  const columns = [
    { field: 'id', headerName: 'Service ID', minWidth: 200, flex: 0.5 },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 350,
      flex: 1,
    },
    {
      field: 'actions',
      flex: 0.3,
      headerName: 'Actions',
      minWidth: 150,
      type: 'number',
      sortable: false,
      renderCell: (params) => {
        return (
          <Fragment>
            <Link to={`/admin/service/${params.getValue(params.id, 'id')}`}>
              <EditIcon />
            </Link>

            <Button
              onClick={() =>
                deleteServiceHandler(params.getValue(params.id, 'id'))
              }
            >
              <DeleteIcon />
            </Button>
          </Fragment>
        )
      },
    },
  ]

  const rows = []

  services &&
    services.forEach((item) => {
      rows.push({
        id: item._id,
        name: item.name,
      })
    })

  return (
    <Fragment>
      <div className="dashboard">
        <SideBar />
        <div className="serviceListContainer">
          <h1 id="serviceListHeading">ALL SERVICES</h1>
          <center>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              disableSelectionOnClick
              className="serviceListTable"
              autoHeight
            />
          </center>
        </div>
      </div>
    </Fragment>
  )
}

export default ServiceList
