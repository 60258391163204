import React, { Fragment, useEffect, useState } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import './serviceReviews.css'
import { useSelector, useDispatch } from 'react-redux'
import {
  clearErrors,
  getAllReviews,
  deleteReviews,
} from '../../actions/service'
import { useAlert } from 'react-alert'
import { Button } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import Star from '@material-ui/icons/Star'

import SideBar from './Sidebar'
import { DELETE_REVIEW_RESET } from '../../constants/service'

const ServiceReviews = ({ history }) => {
  const dispatch = useDispatch()

  const alert = useAlert()

  const { error: deleteError, isDeleted } = useSelector((state) => state.review)

  const { error, reviews, loading } = useSelector(
    (state) => state.serviceReviews,
  )

  const [serviceId, setServiceId] = useState('')

  const deleteReviewHandler = (reviewId) => {
    var result = window.confirm('Comfirm delete?')
    if (result) {
      dispatch(deleteReviews(reviewId, serviceId))
    }
  }

  const serviceReviewsSubmitHandler = (e) => {
    e.preventDefault()
    dispatch(getAllReviews(serviceId))
  }

  useEffect(() => {
    if (serviceId.length === 24) {
      dispatch(getAllReviews(serviceId))
    }
    if (error) {
      alert.error(error)
      dispatch(clearErrors())
    }

    if (deleteError) {
      alert.error(deleteError)
      dispatch(clearErrors())
    }

    if (isDeleted) {
      alert.success('Review Deleted Successfully')
      history.push('/admin/reviews')
      dispatch({ type: DELETE_REVIEW_RESET })
    }
  }, [dispatch, alert, error, deleteError, history, isDeleted, serviceId])

  const columns = [
    { field: 'id', headerName: 'Review ID', minWidth: 200, flex: 0.5 },

    {
      field: 'user',
      headerName: 'User',
      minWidth: 200,
      flex: 0.6,
    },

    {
      field: 'comment',
      headerName: 'Comment',
      minWidth: 350,
      flex: 1,
    },

    {
      field: 'rating',
      headerName: 'Rating',
      type: 'number',
      minWidth: 180,
      flex: 0.4,

      cellClassName: (params) => {
        return params.getValue(params.id, 'rating') >= 3
          ? 'greenColor'
          : 'redColor'
      },
    },

    {
      field: 'actions',
      flex: 0.3,
      headerName: 'Actions',
      minWidth: 150,
      type: 'number',
      sortable: false,
      renderCell: (params) => {
        return (
          <Fragment>
            <Button
              onClick={() =>
                deleteReviewHandler(params.getValue(params.id, 'id'))
              }
            >
              <DeleteIcon />
            </Button>
          </Fragment>
        )
      },
    },
  ]

  const rows = []

  reviews &&
    reviews.forEach((item) => {
      rows.push({
        id: item._id,
        rating: item.rating,
        comment: item.comment,
        user: item.name,
      })
    })

  return (
    <Fragment>
      <div className="dashboard">
        <SideBar />
        <div className="serviceReviewsContainer">
          <form
            className="serviceReviewsForm"
            onSubmit={serviceReviewsSubmitHandler}
          >
            <h1 className="serviceReviewsFormHeading">ALL REVIEWS</h1>

            <div>
              <Star />
              <input
                type="text"
                placeholder="Service Id"
                required
                value={serviceId}
                onChange={(e) => setServiceId(e.target.value)}
              />
            </div>

            <Button
              id="createServiceBtn"
              type="submit"
              disabled={
                loading ? true : false || serviceId === '' ? true : false
              }
            >
              {loading ? 'Search...' : 'Search'}
            </Button>
          </form>

          {reviews && reviews.length > 0 ? (
            <div style={{ padding: '0 5rem' }}>
              <center>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={10}
                  disableSelectionOnClick
                  className="serviceListTable"
                  autoHeight
                />
              </center>
            </div>
          ) : (
            <h1 className="serviceReviewsFormHeading">No Reviews Found</h1>
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default ServiceReviews
