import React, { Fragment, useEffect, useState } from 'react'
import './newSite.css'
import { useSelector, useDispatch } from 'react-redux'
import { clearErrors, createSite } from '../../actions/site'
import { useAlert } from 'react-alert'
import { Button } from '@material-ui/core'
import { RegionDropdown, CountryDropdown } from 'react-country-region-selector'
import Input from 'react-phone-number-input/input'
import SideBar from './Sidebar'
import { NEW_SITE_RESET } from '../../constants/site'
import { getSite } from './../../actions/site'

const NewSite = ({ history }) => {
  const dispatch = useDispatch()
  const alert = useAlert()

  const { loading, error, success } = useSelector((state) => state.newSite)
  const { sites } = useSelector((state) => state.sites)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [termsAndCondition, setTermsAndCondition] = useState('')
  const [privacyPolicy, setPrivacyPolicy] = useState('')
  const [about, setAbout] = useState('')
  const [mapUrl, setMapUrl] = useState('')
  const [vision, setVision] = useState('')
  const [mission, setMission] = useState('')
  const [country, setCountry] = useState('')
  const [state, setState] = useState('')
  const [address, setAddress] = useState('')
  const [whatsappUrl, setWhatsappUrl] = useState('')
  const [twitterUrl, setTwitterUrl] = useState('')
  const [facebookUrl, setFacebookUrl] = useState('')
  const [instagramUrl, setInstagramUrl] = useState('')
  const [openingHours, setOpeningHours] = useState('')
  const [phoneNo, setPhoneNo] = useState('')
  const [phoneNo2, setPhoneNo2] = useState('')
  const [images, setImages] = useState([])
  const [imagesPreview, setImagesPreview] = useState([])

  useEffect(() => {
    if (error) {
      alert.error(error)
      dispatch(clearErrors())
    }

    if (success) {
      alert.success('Site Created Successfully')
      history.push('/admin/dashboard')
      dispatch({ type: NEW_SITE_RESET })
    }
  }, [dispatch, alert, error, history, success])

  useEffect(() => {
    dispatch(getSite())
  }, [dispatch])

  useEffect(() => {
    if (sites && sites.length == 1) {
      return history.push('/admin/sites')
    }
  }, [])

  const createSiteSubmitHandler = (e) => {
    e.preventDefault()

    const myForm = new FormData()

    myForm.set('name', name)
    myForm.set('email', email)
    myForm.set('termsAndCondition', termsAndCondition)
    myForm.set('privacyPolicy', privacyPolicy)
    myForm.set('about', about)
    myForm.set('mapUrl', mapUrl)
    myForm.set('vision', vision)
    myForm.set('mission', mission)
    myForm.set('country', country)
    myForm.set('state', state)
    myForm.set('address', address)
    myForm.set('whatsappUrl', whatsappUrl)
    myForm.set('twitterUrl', twitterUrl)
    myForm.set('facebookUrl', facebookUrl)
    myForm.set('instagramUrl', instagramUrl)
    myForm.set('openingHours', openingHours)
    myForm.set('phoneNo', phoneNo)
    myForm.set('phoneNo2', phoneNo2)

    if (!name) {
      alert.error('Name is required.')
      return
    }
    if (!email) {
      alert.error('Email is required.')
      return
    }
    if (!termsAndCondition) {
      alert.error('Terms and condition is required.')
      return
    }
    if (!privacyPolicy) {
      alert.error('Privacy policy is required.')
      return
    }
    if (!about) {
      alert.error('About is required.')
      return
    }
    if (!vision) {
      alert.error('Vision is required.')
      return
    }
    if (!mission) {
      alert.error('Mission is required.')
      return
    }
    if (!country) {
      alert.error('Country is required.')
      return
    }
    if (!state) {
      alert.error('State is required.')
      return
    }
    if (!address) {
      alert.error('Address is required.')
      return
    }
    if (!openingHours) {
      alert.error('Opening hours is required.')
      return
    }
    if (!phoneNo) {
      alert.error('Phone number is required.')
      return
    }
    if (!phoneNo2) {
      alert.error('Please provide your emergency phone number.')
      return
    }
    if (!images) {
      alert.error('Image is required.')
      return
    }

    images.forEach((image) => {
      myForm.append('images', image)
    })
    dispatch(createSite(myForm))
  }

  const createSiteImagesChange = (e) => {
    const files = Array.from(e.target.files)

    setImages([])
    setImagesPreview([])

    files.forEach((file) => {
      const reader = new FileReader()

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImagesPreview((old) => [...old, reader.result])
          setImages((old) => [...old, reader.result])
        }
      }

      reader.readAsDataURL(file)
    })
  }

  return (
    <Fragment>
      <div className="dashboard">
        <SideBar />
        <div className="newSiteContainer">
          <form
            className="createSiteForm "
            encType="multipart/form-data"
            onSubmit={createSiteSubmitHandler}
          >
            <h1>Create Site</h1>
            <div>
              <input
                type="text"
                placeholder="Company name ..."
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <input
                className="mt-3"
                type="email"
                placeholder="Company valid email address ..."
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <input
                className="mt-3"
                type="text"
                placeholder="what are your opening hours?"
                value={openingHours}
                onChange={(e) => setOpeningHours(e.target.value)}
              />
            </div>
            <div>
              <CountryDropdown
                value={country}
                style={{ color: 'grey' }}
                className="mt-3"
                onChange={setCountry}
                name="country"
              />
            </div>
            <div>
              <RegionDropdown
                disableWhenEmpty={true}
                country={country}
                style={{ color: 'grey' }}
                className="mt-3"
                value={state}
                onChange={setState}
                name="region"
              />
            </div>
            <div>
              <Input
                placeholder="Company phone Number .... (Start with country code)"
                value={phoneNo}
                className="mt-3"
                onChange={setPhoneNo}
              />
            </div>
            <div>
              <Input
                placeholder="Company emergency phone Number .... (Start with country code)"
                value={phoneNo2}
                className="mt-3"
                onChange={setPhoneNo2}
              />
            </div>
            <div>
              <input
                className="mt-3"
                type="text"
                placeholder="Company address ..."
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div>
              <input
                className="mt-3"
                type="text"
                placeholder="Company map link (Optional)"
                value={mapUrl}
                onChange={(e) => setMapUrl(e.target.value)}
              />
            </div>
            <div>
              <input
                className="mt-3"
                type="text"
                placeholder="Company whatsApp link (Optional)"
                value={whatsappUrl}
                onChange={(e) => setWhatsappUrl(e.target.value)}
              />
            </div>
            <div>
              <input
                className="mt-3"
                type="text"
                placeholder="Company twitter link (Optional)"
                value={twitterUrl}
                onChange={(e) => setTwitterUrl(e.target.value)}
              />
            </div>
            <div>
              <input
                className="mt-3"
                type="text"
                placeholder="Company facebook link (Optional)"
                value={facebookUrl}
                onChange={(e) => setFacebookUrl(e.target.value)}
              />
            </div>
            <div>
              <input
                className="mt-3"
                type="text"
                placeholder="Company instagram link (Optional)"
                value={instagramUrl}
                onChange={(e) => setInstagramUrl(e.target.value)}
              />
            </div>

            <div>
              <textarea
                className="mt-3"
                placeholder="Write about your company..."
                value={about}
                onChange={(e) => setAbout(e.target.value)}
                cols="30"
                rows="10"
              ></textarea>
            </div>
            <div>
              <textarea
                className="mt-3"
                placeholder="Write about your company vision..."
                value={vision}
                onChange={(e) => setVision(e.target.value)}
                cols="30"
                rows="10"
              ></textarea>
            </div>
            <div>
              <textarea
                className="mt-3"
                placeholder="Write about your company mission..."
                value={mission}
                onChange={(e) => setMission(e.target.value)}
                cols="30"
                rows="10"
              ></textarea>
            </div>
            <div>
              <textarea
                className="mt-3"
                placeholder="Write about your company privacy policy..."
                value={privacyPolicy}
                onChange={(e) => setPrivacyPolicy(e.target.value)}
                cols="30"
                rows="10"
              ></textarea>
            </div>
            <div>
              <textarea
                className="mt-3"
                placeholder="Write about your company terms and condition ..."
                value={termsAndCondition}
                onChange={(e) => setTermsAndCondition(e.target.value)}
                cols="30"
                rows="10"
              ></textarea>
            </div>

            <div id="createSiteFormFile">
              <input
                className="mt-3"
                type="file"
                name="avatar"
                accept="image/*"
                onChange={createSiteImagesChange}
                multiple
              />
            </div>

            <div id="createSiteFormImage" className="mt-3">
              {imagesPreview.map((image, index) => (
                <img key={index} src={image} alt="Site Preview" />
              ))}
            </div>

            <Button
              className="mt-3"
              id="createSiteBtn"
              type="submit"
              disabled={loading ? true : false}
            >
              {loading ? 'Create...' : 'Create'}
            </Button>
          </form>
        </div>
      </div>
    </Fragment>
  )
}

export default NewSite
