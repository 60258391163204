import React, { Fragment, useEffect } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import './siteList.css'
import { useSelector, useDispatch } from 'react-redux'
import { clearErrors, getAdminSite, deleteSite } from '../../actions/site'
import { Link } from 'react-router-dom'
import { useAlert } from 'react-alert'
import { Button } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import SideBar from './Sidebar'
import { DELETE_SITE_RESET } from '../../constants/site'

const SiteList = ({ history }) => {
  const dispatch = useDispatch()

  const alert = useAlert()
  const { user } = useSelector((state) => state.user)

  const { error, sites } = useSelector((state) => state.sites)

  const { error: deleteError, isDeleted } = useSelector((state) => state.site)

  const deleteSiteHandler = (id) => {
    var result = window.confirm('Comfirm delete?')
    if (result) {
      dispatch(deleteSite(id))
    }
  }

  useEffect(() => {
    if (error) {
      alert.error(error)
      dispatch(clearErrors())
    }

    if (deleteError) {
      alert.error(deleteError)
      dispatch(clearErrors())
    }

    if (isDeleted) {
      alert.success('Site Deleted Successfully')
      history.push('/admin/dashboard')
      dispatch({ type: DELETE_SITE_RESET })
    }

    dispatch(getAdminSite())
  }, [dispatch, alert, error, deleteError, history, isDeleted])

  const columns = [
    { field: 'id', headerName: 'Site ID', minWidth: 200, flex: 0.5 },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 350,
      flex: 1,
    },
    {
      field: 'actions',
      flex: 0.3,
      headerName: 'Actions',
      minWidth: 150,
      type: 'number',
      sortable: false,
      renderCell: (params) => {
        return (
          <Fragment>
            {user.root === 'No' ? (
              'Not a Root Admin'
            ) : (
              <>
                <Link to={`/admin/site/${params.getValue(params.id, 'id')}`}>
                  <EditIcon />
                </Link>

                <Button
                  onClick={() =>
                    deleteSiteHandler(params.getValue(params.id, 'id'))
                  }
                >
                  <DeleteIcon />
                </Button>
              </>
            )}
          </Fragment>
        )
      },
    },
  ]

  const rows = []

  sites &&
    sites.forEach((item) => {
      rows.push({
        id: item._id,
        name: item.name,
      })
    })

  return (
    <Fragment>
      <div className="dashboard">
        <SideBar />
        <div className="siteListContainer">
          <h1 id="siteListHeading">ALL SITES</h1>
          <center>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              disableSelectionOnClick
              className="siteListTable"
              autoHeight
            />
          </center>
        </div>
      </div>
    </Fragment>
  )
}

export default SiteList
