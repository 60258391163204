import React, { useEffect } from 'react'
import './PrivacyPolicy.css'
import { Link } from 'react-router-dom'
import { getSite } from './../../../actions/site'
import { useDispatch, useSelector } from 'react-redux'
import Loader from './../Loader/Loader'

const PrivacyPolicy = () => {
  const { loading, sites } = useSelector((state) => state.sites)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSite())
  }, [dispatch])

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {sites &&
            sites.map((site) => (
              <div>
                {/*  Inner page title  */}
                <div class="inner-page-title-area privacy-policy">
                  <div class="container">
                    {/* breadcrumb */}
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Privacy Policy
                      </li>
                    </ol>
                    {/* breadcrumb end */}
                    <div class="row">
                      <div class="col-lg-6 offset-lg-3">
                        {/* title & des */}
                        <h1>
                          <span>Privacy Policy</span>
                        </h1>
                        {/* title & des end */}
                      </div>
                    </div>
                  </div>
                </div>
                {/*  Inner page title end  */}

                {/*  Privacy policy page  */}
                <div class="privacy-policy-page pt-80 pb-80">
                  <div class="container">
                    <div class="privacy-policy-single-content">
                      <h3 class="mb-10">Privacy Policy</h3>
                      <p>
                        {site.privacyPolicy}
                      </p>
                    </div>
                  </div>
                </div>
                {/*  Privacy policy page end  */}
              </div>
            ))}
        </div>
      )}
    </>
  )
}

export default PrivacyPolicy
