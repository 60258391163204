import React from 'react'
import './NotFound.css'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <>
      {/*  Inner page title  */}
      <div className="inner-page-title-area not-found">
        <div className="container">
          {/* breadcrumb */}
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="index.html">Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Page Not Found
            </li>
          </ol>
          {/* breadcrumb end */}
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              {/* title & des */}
              <h1>
                <span>Page Not Found</span>
              </h1>
              {/* title & des end */}
            </div>
          </div>
        </div>
      </div>
      {/*  Inner page title end  */}

      {/*  404 page  */}
      <div className="404-page pt-80 pb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              {/* error text */}
              <div className="error-text text-center">
                <h2>404</h2>
                <h4>Sorry, but the page was not found</h4>

                <Link to="/" className="btn-style-1">
                  back to home page
                </Link>
              </div>
              {/* error text end */}
            </div>
          </div>
        </div>
      </div>
      {/*  404 page end  */}
    </>
  )
}

export default NotFound
