import React, { Fragment, useEffect, useState } from 'react'
import MetaData from '../layout/MetaData'
import './Services.css'
import { useSelector, useDispatch } from 'react-redux'
import { clearErrors, getService } from '../../actions/service'
import Loader from '../layout/Loader/Loader'
import Pagination from 'react-js-pagination'
import { useAlert } from 'react-alert'
import { Link } from 'react-router-dom'
import { getSite } from './../../actions/site'
import ServiceDetailsCard from './ServiceDetailsCard'

const Services = ({ match }) => {
  const dispatch = useDispatch()

  const alert = useAlert()

  const { sites } = useSelector((state) => state.sites)

  const [currentPage, setCurrentPage] = useState(1)
  const [price, setPrice] = useState([0, 25000])
  const [category, setCategory] = useState('')

  const [ratings, setRatings] = useState(0)

  const {
    services,
    loading,
    error,
    servicesCount,
    resultPerPage,
    filteredServicesCount,
  } = useSelector((state) => state.services)

  const keyword = match.params.keyword

  const setCurrentPageNo = (e) => {
    setCurrentPage(e)
  }

  let count = filteredServicesCount

  useEffect(() => {
    if (error) {
      alert.error(error)
      dispatch(clearErrors())
    }

    dispatch(getService(keyword, currentPage, price, category, ratings))
  }, [dispatch, keyword, currentPage, price, category, ratings, alert, error])

  useEffect(() => {
    dispatch(getSite())
  }, [dispatch])
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          {sites &&
            sites.map((site) => (
              <MetaData title={` ${site.name} | Services`} />
            ))}
          {/*  Inner page title  */}
          <div className="inner-page-title-area services-breadcrumb">
            <div className="container">
              {/* breadcrumb */}
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Services
                </li>
              </ol>
              {/* breadcrumb end */}
              <div className="row">
                <div className="col-lg-6 offset-lg-3 text-white">
                  {/* title & des */}
                  <h1 className="text-white">
                    <span>Services</span>
                  </h1>
                  {/* title & des end */}
                </div>
              </div>
            </div>
          </div>
          {/*  Inner page title end  */}
          <h2 className="servicesHeading text-dark">Services</h2>
          <div>
            {/*  Services area  */}
            <div class="services-area pt-80 pb-50">
              <div class="container">
                <div class="row row-cols-lg-3 row-cols-md-2 row-cols-sm-1 row-cols-1">
                  {services &&
                    services.map((service) => (
                      <ServiceDetailsCard key={service._id} service={service} />
                    ))}
                </div>
              </div>
            </div>
          </div>

          {resultPerPage < count && (
            <div className="paginationBox">
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={resultPerPage}
                totalItemsCount={servicesCount}
                onChange={setCurrentPageNo}
                nextPageText="Next"
                prevPageText="Prev"
                firstPageText="1st"
                lastPageText="Last"
                itemClass="page-item"
                linkClass="page-link"
                activeClass="pageItemActive"
                activeLinkClass="pageLinkActive"
              />
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

export default Services
