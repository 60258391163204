import React, { useState, useEffect } from 'react'
import './Faq.css'
import { Link } from 'react-router-dom'
import { getSite } from './../../../actions/site'
import emailjs from 'emailjs-com'
import { useDispatch, useSelector } from 'react-redux'
import Loader from './../Loader/Loader'
import { useAlert } from 'react-alert'
import Input from 'react-phone-number-input/input'

const FAQ = () => {
  const [phoneNo, setPhoneNo] = useState('')
  const [sending, setSending] = useState(false)

  const { loading, sites } = useSelector((state) => state.sites)
  const alert = useAlert()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSite())
  }, [dispatch])

  const sendMail = (e) => {
    e.preventDefault()
    setSending(true)
    emailjs
      .sendForm(
        'spezin',
        'template_39tdeiq',
        e.target,
        'user_UdgQW2uxRt0vdGCQzkK9Y',
      )
      .then((res) => {
        alert.success(
          'Message sent....we will get back to you as soon as possible.',
        )
        setSending(false)
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      })
      .catch((err) => {
        alert.error('Message Failed. Please try again.')
        setSending(false)
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      })
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {/*  Inner page title  */}
          <div className="inner-page-title-area faq">
            <div className="container">
              {/* breadcrumb */}
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Faq
                </li>
              </ol>
              {/* breadcrumb end */}
              <div className="row">
                <div className="col-lg-6 offset-lg-3">
                  {/* title & des */}
                  <h1>
                    <span>Faq</span>
                  </h1>
                  {/* title & des end */}
                </div>
              </div>
            </div>
          </div>
          {/*  Inner page title end  */}

          {/*  Faq page  */}
          <div className="faq-page pt-80 pb-50">
            <div className="container">
              <div className="row">
                <div className="col-lg-9">
                  {/* Accordion */}
                  <div className="accordion faq-box" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          01. Are you ISO certified?
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p className="mb-0 text-white">
                            Lorem ipsum dolor sitametcoctr elit amet consectur
                            aiscing elit amet adipisg elit adipisg elit amet
                            consectur orem ipsum dolor ipsum dolor sitametcoctr
                            elit amet consectur aiscing elit amet adipisg elit
                            adipisg elit amet consectur orem ipsum dolor ipsum
                            dolor sitametcoctr elit amet consectur aiscing elit
                            amet adipisg elit adipisg elit amet consectur orem
                            ipsum dolor sitametcoctr adipisg elit amet consectur
                            aiscing elit adipig elit amet consectur aiscing elit
                            amet adipisg elit adipisg elit amet consectur.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          02. Which material types can you work with?
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p className="mb-0 text-white">
                            Lorem ipsum dolor sitametcoctr elit amet consectur
                            aiscing elit amet adipisg elit adipisg elit amet
                            consectur orem ipsum dolor ipsum dolor sitametcoctr
                            elit amet consectur aiscing elit amet adipisg elit
                            adipisg elit amet consectur orem ipsum dolor ipsum
                            dolor sitametcoctr elit amet consectur aiscing elit
                            amet adipisg elit adipisg elit amet consectur orem
                            ipsum dolor sitametcoctr adipisg elit amet consectur
                            aiscing elit adipig elit amet consectur aiscing elit
                            amet adipisg elit adipisg elit amet consectur.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          03. How long does it takes to receive the answer?
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p className="mb-0 text-white">
                            Lorem ipsum dolor sitametcoctr elit amet consectur
                            aiscing elit amet adipisg elit adipisg elit amet
                            consectur orem ipsum dolor ipsum dolor sitametcoctr
                            elit amet consectur aiscing elit amet adipisg elit
                            adipisg elit amet consectur orem ipsum dolor ipsum
                            dolor sitametcoctr elit amet consectur aiscing elit
                            amet adipisg elit adipisg elit amet consectur orem
                            ipsum dolor sitametcoctr adipisg elit amet consectur
                            aiscing elit adipig elit amet consectur aiscing elit
                            amet adipisg elit adipisg elit amet consectur.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          04. How to get start with us?
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p className="mb-0 text-white">
                            Lorem ipsum dolor sitametcoctr elit amet consectur
                            aiscing elit amet adipisg elit adipisg elit amet
                            consectur orem ipsum dolor ipsum dolor sitametcoctr
                            elit amet consectur aiscing elit amet adipisg elit
                            adipisg elit amet consectur orem ipsum dolor ipsum
                            dolor sitametcoctr elit amet consectur aiscing elit
                            amet adipisg elit adipisg elit amet consectur orem
                            ipsum dolor sitametcoctr adipisg elit amet consectur
                            aiscing elit adipig elit amet consectur aiscing elit
                            amet adipisg elit adipisg elit amet consectur.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Accordion end */}
                </div>
                <div className="col-lg-3">
                  {/* have any question */}
                  <div className="have-any-question mb-30">
                    <div className="question_form p-30">
                      <h5 className="mb-15 text-white">
                        Have a Any Questions?
                      </h5>
                      <form
                        onSubmit={sendMail}
                        className="contact-form"
                        id="contact-form"
                      >
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            id="floatingInput1"
                            placeholder="Name"
                            required
                            data-error="Name is required"
                          />
                          <label for="floatingInput1" className='text-white'>Name</label>
                          <div className="help-block with-errors"></div>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            id="floatingInput2"
                            placeholder="E-mail"
                            required
                            data-error="E-mail is required"
                          />
                          <label for="floatingInput2" className='text-white'>E-mail</label>
                          <div className="help-block with-errors"></div>
                        </div>
                        <div className="form-floating mb-3">
                          <Input
                            value={phoneNo}
                            onChange={setPhoneNo}
                            type="text"
                            className="form-control pl-3 pr-3"
                            name="phone"
                            id="floatingInput3"
                            placeholder="Phone"
                            required
                            data-error="Phone is required"
                          />
                          <label for="floatingInput3" className='text-white'>Phone</label>
                          <div className="help-block with-errors"></div>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            name="subject"
                            id="floatingInput3"
                            placeholder="Subject"
                            required
                            data-error="Subject is required"
                          />
                          <label for="floatingInput3" className='text-white'>Subject</label>
                          <div className="help-block with-errors"></div>
                        </div>
                        <div className="form-floating mb-3">
                          <textarea
                            className="form-control"
                            name="message"
                            id="floatingTextarea"
                            placeholder="Your Message"
                            required
                            data-error="Message is required"
                          ></textarea>
                          <label for="floatingTextarea" className='text-white'>Your Message</label>
                          <div className="help-block with-errors"></div>
                        </div>
                        {sending ? (
                          <button
                            type="submit"
                            disabled
                            className="btn-style-1"
                          >
                            Sending...
                          </button>
                        ) : (
                          <button type="submit" className="btn-style-1">
                            Send
                          </button>
                        )}
                        <div className="messages"></div>
                      </form>
                    </div>
                  </div>
                  {/* have any question end */}
                </div>
              </div>
            </div>
          </div>
          {/*  Faq page end  */}
        </div>
      )}
    </>
  )
}

export default FAQ
