import React, { Fragment, useEffect } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import './serviceList.css'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useAlert } from 'react-alert'
import { Button } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import SideBar from './Sidebar'
import { getAllUsers, clearErrors, deleteUser } from '../../actions/user'
import { DELETE_USER_RESET } from '../../constants/user'

const UsersList = ({ history }) => {
  const dispatch = useDispatch()

  const alert = useAlert()

  const { error, users } = useSelector((state) => state.allUsers)
  const { user, isAuthenticated } = useSelector((state) => state.user)

  const { error: deleteError, isDeleted, message } = useSelector(
    (state) => state.profile,
  )

  useEffect(() => {
    if (isAuthenticated === false) {
      history.push('/login')
    }
  }, [history, isAuthenticated])

  const deleteUserHandler = (id) => {
    var result = window.confirm('Comfirm delete?')
    if (result) {
      dispatch(deleteUser(id))
      history.push('/admin/users')
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }
  }

  useEffect(() => {
    if (error) {
      alert.error(error)
      dispatch(clearErrors())
      history.push('/admin/users')
    }

    if (deleteError) {
      alert.error(deleteError)
      dispatch(clearErrors())
      history.push('/admin/users')
    }

    if (isDeleted) {
      alert.success(message)
      dispatch({ type: DELETE_USER_RESET })
      history.push('/admin/users')
    }

    dispatch(getAllUsers())
  }, [dispatch, alert, error, deleteError, history, isDeleted, message])

  const columns = [
    { field: 'id', headerName: 'User ID', minWidth: 180, flex: 0.8 },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 150,
      flex: 0.5,
    },

    {
      field: 'role',
      headerName: 'Role',
      type: 'number',
      minWidth: 150,
      flex: 0.3,
      cellClassName: (params) => {
        return params.getValue(params.id, 'role') === 'admin'
          ? 'greenColor'
          : 'redColor'
      },
    },
    {
      field: 'root',
      headerName: 'Root',
      type: 'number',
      minWidth: 150,
      flex: 0.3,
      cellClassName: (params) => {
        return params.getValue(params.id, 'root') === 'Yes'
          ? 'greenColor'
          : 'redColor'
      },
    },

    {
      field: 'actions',
      flex: 0.3,
      headerName: 'Actions',
      minWidth: 150,
      type: 'number',
      sortable: false,
      renderCell: (params) => {
        return (
          <Fragment>
            {user.root === 'Yes' && (
              <Link to={`/admin/user/${params.getValue(params.id, 'id')}`}>
                <EditIcon />
              </Link>
            )}

            <Button
              disabled={user.root === 'No' ? true : false}
              onClick={() =>
                deleteUserHandler(params.getValue(params.id, 'id'))
              }
            >
              <DeleteIcon />
            </Button>
          </Fragment>
        )
      },
    },
  ]

  const rows = []

  users &&
    users.forEach((item) => {
      rows.push({
        id: item._id,
        role: item.role,
        root: item.root,
        email: item.email,
        name: item.name,
      })
    })

  return (
    <Fragment>
      <div className="dashboard">
        <SideBar />
        <div className="serviceListContainer">
          <h1 id="serviceListHeading">ALL USERS</h1>

          <center>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              disableSelectionOnClick
              className="serviceListTable"
              autoHeight
            />
          </center>
        </div>
      </div>
    </Fragment>
  )
}

export default UsersList
