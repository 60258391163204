import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
  newServiceReducer,
  newReviewReducer,
  serviceDetailsReducer,
  serviceReducer,
  serviceReviewsReducer,
  servicesReducer,
  reviewReducer,
} from './reducers/service'

import {
  newSiteReducer,
  siteDetailsReducer,
  siteReducer,
  sitesReducer,
} from './reducers/site'

import {
  allUsersReducer,
  forgotPasswordReducer,
  profileReducer,
  userDetailsReducer,
  userReducer,
} from './reducers/user'

const reducer = combineReducers({
  sites: sitesReducer,
  services: servicesReducer,
  siteDetails: siteDetailsReducer,
  serviceDetails: serviceDetailsReducer,
  user: userReducer,
  profile: profileReducer,
  forgotPassword: forgotPasswordReducer,
  newReview: newReviewReducer,
  newSite: newSiteReducer,
  newService: newServiceReducer,
  service: serviceReducer,
  site: siteReducer,
  allUsers: allUsersReducer,
  userDetails: userDetailsReducer,
  serviceReviews: serviceReviewsReducer,
  review: reviewReducer,
})

const middleware = [thunk]

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware)),
)

export default store
