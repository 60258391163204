import React, { Fragment, useEffect, useState } from 'react'
import './newService.css'
import { useSelector, useDispatch } from 'react-redux'
import { clearErrors, createService } from '../../actions/service'
import { useAlert } from 'react-alert'
import { Button } from '@material-ui/core'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import DescriptionIcon from '@material-ui/icons/Description'
import SpellcheckIcon from '@material-ui/icons/Spellcheck'
import SideBar from './Sidebar'
import { NEW_SERVICE_RESET } from '../../constants/service'


const NewService = ({ history }) => {
  const dispatch = useDispatch()
  const alert = useAlert()

  const { loading, error, success } = useSelector((state) => state.newService)

  const [name, setName] = useState('')
  const [description, setDescription] = useState()
  const [images, setImages] = useState([])
  const [imagesPreview, setImagesPreview] = useState([])

  useEffect(() => {
    if (error) {
      alert.error(error)
      dispatch(clearErrors())
    }

    if (success) {
      alert.success('Service Created Successfully')
      history.push('/admin/dashboard')
      dispatch({ type: NEW_SERVICE_RESET })
    }
  }, [dispatch, alert, error, history, success])

  const createServiceSubmitHandler = (e) => {
    e.preventDefault()

    const myForm = new FormData()

    myForm.set('name', name)
    myForm.set('description', description)

    if (!name) {
      alert.error('Name is required.')
      return
    }
    if (!description) {
      alert.error('Description is required.')
      return
    }
    if (!images) {
      alert.error('Image is required.')
      return
    }

    images.forEach((image) => {
      myForm.append('images', image)
    })
    dispatch(createService(myForm))
  }

  const createServiceImagesChange = (e) => {
    const files = Array.from(e.target.files)

    setImages([])
    setImagesPreview([])

    files.forEach((file) => {
      const reader = new FileReader()

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImagesPreview((old) => [...old, reader.result])
          setImages((old) => [...old, reader.result])
        }
      }

      reader.readAsDataURL(file)
    })
  }

  return (
    <Fragment>
      <div className="dashboard">
        <SideBar />
        <div className="newServiceContainer">
          <form
            className="createServiceForm"
            encType="multipart/form-data"
            onSubmit={createServiceSubmitHandler}
          >
            <h1>Create Service</h1>

            <div>
              <SpellcheckIcon />
              <input
                type="text"
                placeholder="Service Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div>
              <DescriptionIcon />
              <textarea
                placeholder="Service Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                cols="30"
                rows="10"
              ></textarea>
            </div>

            <div id="createServiceFormFile">
              <input
                type="file"
                name="avatar"
                accept="image/*"
                onChange={createServiceImagesChange}
                multiple
              />
            </div>

            <div id="createServiceFormImage">
              {imagesPreview.map((image, index) => (
                <img key={index} src={image} alt="Service Preview" />
              ))}
            </div>

            <Button
              id="createServiceBtn"
              type="submit"
              disabled={loading ? true : false}
            >
              {loading ? 'Create...' : 'Create'}
            </Button>
          </form>
        </div>
      </div>
    </Fragment>
  )
}

export default NewService
