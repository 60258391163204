import React from 'react'
import playStore from '../../../images/playstore.png'
import appStore from '../../../images/Appstore.png'
import './Footer.css'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="theme-bg-dark pt-30 pb-10">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8 col-md-8 mb-20">
            {/* footer links */}
            <ul className="footer-links">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/services">Services</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                <Link to="/faq">About Us</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-condition">Terms & Condition</Link>
              </li>
            </ul>
            {/* footer links end */}
          </div>
          <div className="col-lg-4 col-md-4 text-lg-end text-md-end text-sm-center text-center mb-20">
            {/* footer copyright */}
            <div className="footer-copyright">
              {' '}
              © <span className="current-year"></span> All Rights Reserved.{' '}
            </div>
            {/* footer copyright end */}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
