import { Rating } from "@material-ui/lab";
import React from "react";
import profilePng from "../../images/Profile.png";
import { useSelector } from "react-redux";

const ReviewCard = ({ review }) => {
  const options = {
    value: review.rating,
    readOnly: true,
    precision: 0.5,
  };
  const { user } = useSelector((state) => state.user);


  return (
    <div className="reviewCard">
      <img style={{borderRadius: "100%", width: "80px", height: "70px"}} src={user.avatar.url} alt="User" />
      <p className="text-dark">{review.name}</p>
      <Rating {...options} />
      <span className="reviewCardComment text-dark">{review.comment}</span>
    </div>
  );
};

export default ReviewCard;
