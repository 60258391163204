import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../../actions/user'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { useAlert } from 'react-alert'
import DashboardIcon from '@material-ui/icons/Dashboard'
import PersonIcon from '@material-ui/icons/Person'
import { getSite } from './../../../actions/site'
import MetaData from '../MetaData'

const Header = () => {
  const [navbar, setNavbar] = useState(false)
  const { user, isAuthenticated } = useSelector((state) => state.user)
  const { sites } = useSelector((state) => state.sites)

  const alert = useAlert()
  const dispatch = useDispatch()

  function logoutUser() {
    dispatch(logout())
    alert.success('Logout Successfully')
  }

  useEffect(() => {
    dispatch(getSite())
  }, [dispatch])

  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener('scroll', changeBackground)
  })

  const pathname = window.location.pathname

  return (
    <header className="header">
      {/* header lover */}
      {sites &&
        sites.map((site) => (
          <>
            <MetaData
              title={
                pathname === '/'
                  ? site.name
                  : `${site.name} | ${
                      pathname.charAt(1).toUpperCase() +
                      pathname.slice(2).replace(/[^a-zA-Z0-9-_]/g, ' ')
                    }`
              }
              content="Welcome to Spezin Nigeria Limited. A leading Nigeria company in truck and logistics, sales of tractor and water supply services."
            />

            <div
              className={
                navbar ? 'header-lover header-lover-white' : 'header-lover'
              }
              key={site._id}
            >
              <div className="header-container">
                <div className="row align-items-center">
                  <div className="col-lg-10">
                    <nav className="navbar navbar-expand-md">
                      {/* logo */}
                      <Link className="navbar-brand" to="/">
                        <img
                          src={site.images[0].url}
                          alt=""
                          style={{ height: '50px' }}
                        />
                      </Link>
                      {/* logo end */}
                      <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbars01"
                        aria-controls="navbars01"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span></span> <span></span> <span></span>
                      </button>
                      <div className="collapse navbar-collapse" id="navbars01">
                        <ul className="navbar-nav ms-auto">
                          <li className="nav-item active">
                            <Link className="nav-link" to="/">
                              Home
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link" to="/services">
                              Services
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link" to="/about">
                              About
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link" to="/contact">
                              Contact
                            </Link>
                          </li>

                          <li className="nav-item dropdown">
                            <Link
                              className="nav-link dropdown-toggle"
                              to="/#"
                              id="dropdown04"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Others
                            </Link>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdown04"
                            >
                              <li>
                                <Link className="dropdown-item" to="/faq">
                                  Faq
                                </Link>
                              </li>

                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/privacy-policy"
                                >
                                  Privacy Policy
                                </Link>
                              </li>
                            </ul>
                          </li>
                          {!isAuthenticated ? (
                            <li>
                              <Link
                                className="nav-link btn-style-4"
                                to="/login"
                              >
                                <PersonIcon /> Join&nbsp;Us&nbsp;Or&nbsp;Login
                              </Link>
                            </li>
                          ) : (
                            <>
                              <li>
                                <Link
                                  className="nav-link btn-style-4"
                                  to={
                                    user.role === 'admin'
                                      ? '/admin/dashboard'
                                      : '/account'
                                  }
                                >
                                  <DashboardIcon />
                                  &nbsp;Dashboard
                                </Link>
                              </li>
                              <li>
                                <span
                                  className="nav-link btn-style-4"
                                  onClick={logoutUser}
                                >
                                  <ExitToAppIcon />
                                  &nbsp;Logout
                                </span>
                              </li>
                              <li>
                                <Link
                                  className="nav-link btn-style-4"
                                  to="/qrcode"
                                >
                                  <DashboardIcon />
                                  &nbsp;
                                  <span className="qrcode">Qr&nbsp;Code</span>
                                </Link>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    </nav>
                  </div>

                  <div className="col-lg-2 text-lg-end">
                    {/* header social */}
                    <div className="header-social">
                      <a href={site.facebookUrl ? site.facebookUrl : '#'}>
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href={site.twitterUrl ? site.twitterUrl : '#'}>
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href={site.instagramUrl ? site.instagramUrl : '#'}>
                        <i className="fab fa-instagram"></i>
                      </a>
                      {/* <a href={site.whatsappUrl ? site.whatsappUrl : '#'}>
                        <i className="fab fa-whatsapp"></i>
                      </a> */}
                    </div>
                    {/* header social end */}
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
      {/* header lover end */}
    </header>
  )
}

export default Header
