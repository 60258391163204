import React, { useEffect } from 'react'
import './sidebar.css'
import { Link } from 'react-router-dom'
import { TreeView, TreeItem } from '@material-ui/lab'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PostAddIcon from '@material-ui/icons/PostAdd'
import AddIcon from '@material-ui/icons/Add'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import DashboardIcon from '@material-ui/icons/Dashboard'
import PeopleIcon from '@material-ui/icons/People'
import RateReviewIcon from '@material-ui/icons/RateReview'
import PersonIcon from '@material-ui/icons/Person'
import Loader from './../layout/Loader/Loader'
import { getSite } from '../../actions/site'
import { useSelector, useDispatch } from 'react-redux'

const Sidebar = () => {
  const dispatch = useDispatch()
  const { loading, sites } = useSelector((state) => state.sites)
  useEffect(() => {
    dispatch(getSite())
  }, [dispatch])
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {sites &&
            sites.map((site) => (
              <div className="sidebar mt-5">
                <Link to="/" style={{ marginLeft: '-50px' }}>
                  <img
                    src={site.images[0].url}
                    style={{ height: '100px' }}
                    alt={site.name}
                  />
                </Link>

                <b>
                  <Link to="/admin/dashboard">
                    <p>
                      <DashboardIcon /> Dashboard
                    </p>
                  </Link>
                </b>
                <b>
                  <Link to="/admin/sites">
                    <p>
                      <PostAddIcon /> All Sites
                    </p>
                  </Link>
                </b>
                <b>
                  <Link to="/admin/site">
                    <p>
                      <AddIcon /> Create New Site
                    </p>
                  </Link>
                </b>
                <b>
                  <Link to="/admin/services">
                    <p>
                      <PostAddIcon /> All Services
                    </p>
                  </Link>
                </b>
                <b>
                  <Link to="/admin/service">
                    <p>
                      <AddIcon /> Create New Service
                    </p>
                  </Link>
                </b>

                <b>
                  <Link to="/admin/users">
                    <p>
                      <PeopleIcon /> Users
                    </p>
                  </Link>
                </b>

                <b>
                  <Link to="/admin/reviews">
                    <p>
                      <RateReviewIcon />
                      Reviews
                    </p>
                  </Link>
                </b>

                <b>
                  <Link to="/account">
                    <p>
                      <PersonIcon />
                      Acccount
                    </p>
                  </Link>
                </b>
              </div>
            ))}
        </>
      )}
    </>
  )
}

export default Sidebar
